<template>
  <div id="sign_in">
    <p class="rule" @click="ruleShow = true">签到规则</p>
    <van-dialog
      v-model="ruleShow"
      title="签到规则"
      theme="round-button"
      confirmButtonText="我知道了"
      confirmButtonColor="#FB6E10"
      class="rule_popover"
    >
      <ul>
        <li>1. 每日签到1次，每次签到奖励相应积分；</li>
        <li>2. 每周累计完成签到规定天数，可获得额外奖励，不设补签；</li>
        <li>3. 积分即时到账，请在积分有效期内使用；</li>
        <li>4. 签到信息将在每周一重置，开始新一轮累计；</li>
        <li>5. 积分最终解释权归正品汇平台所有。</li>
      </ul>
    </van-dialog>
    <div class="top">
      <div class="box">
        <div class="my_integraly" style="">
          <img src="@/assets/images/sign_in_head_bg.png" width="100%" />
          <div class="details">
            <h4>
              我的平台积分<span
                @click="$router.push('integral?bankId=0&name=正品汇平台积分')"
                >明细<svg-icon icon-class="arrow_right"
              /></span>
            </h4>
            <h3>{{ zphIntegral }}</h3>
          </div>
        </div>
        <div class="text">
          100积分抵现1元，正品汇商城正在进行积分抵现满减优惠活动，<router-link
            to="/home/homePage"
            >去下单&gt;&gt;</router-link
          >
        </div>
      </div>
    </div>
    <div class="sign_in_module">
      <div class="title">
        <div
          style="
            font-size: 16px;
            width: 100px;
            height: 36px;
            line-height: 36px;
            background-position: -10px -10px;
          "
        >
          每日签到
        </div>
        <p>
          周累计签到<span v-for="(item, i) in bonusPoints" :key="i"
            >{{ item.days }}天{{
              bonusPoints.length === i + 1 ? "" : "/"
            }}</span
          >可获得额外奖励
        </p>
      </div>
      <div class="content">
        <h4>
          已累积签到<span>{{ signInDaysNum }}</span
          >天
        </h4>
        <div class="days">
          <div class="day" v-for="(item, index) in signInDaysList" :key="index">
            <span
              class="tips"
              v-if="item.extraIntegral || item.doubling"
              style="
                width: 45px;
                height: 30px;
                background-position: -10px -66px;
                line-height: 22px;
              "
              >{{ item.doubling ? "翻倍" : item.extraIntegral }}</span
            >
            <div
              class="integral"
              :style="item.days <= signInDaysNum ? 'background:#FFC426' : ''"
            >
              <span
                class="num"
                :style="item.days <= signInDaysNum ? 'color:#fff' : ''"
                >+{{ item.integral }}</span
              >
              <span
                class="icon"
                style="
                  width: 27px;
                  height: 27px;
                  background-position: -75px -66px;
                "
              ></span>
            </div>
            <span class="number_day">第{{ item.days }}天</span>
          </div>
        </div>
      </div>
      <van-button
        round
        :color="
          isSignIn
            ? '#FFB365'
            : 'linear-gradient(180deg, #FE9429 0%, #FC7011 100%)'
        "
        @click="signIn"
        >{{ isSignIn ? "今日已签到，明日记得再来~" : "签到" }}</van-button
      >
      <!-- <van-button round color="#FFB365">今日已签到，明日记得再来~</van-button> -->
    </div>
    <h3 class="title_text">做任务赚积分</h3>
    <!-- taskList -->
    <div class="box_content" v-for="list in taskList" :key="list.type">
      <template v-if="list.show">
        <h4>{{ list.title }}</h4>
        <van-cell
          v-for="item in list.list"
          :key="item.id"
          v-show="item.taskStatus"
        >
          <template #title>
            <div>
              {{ item.taskName
              }}<template
                >（{{ item.times }}/{{
                  item.totalLimit ? item.totalLimit : item.upLimit
                }}）</template
              ><span>+{{ item.integral }}积分</span>
            </div>
            <p class="introduction">{{ item.taskDesctiption }}</p>
            <!-- <p class="time">2023-06-30任务到期</p> -->
          </template>
          <template #right-icon>
            <van-button
              round
              :color="
                item.getIntegralTimes
                  ? 'linear-gradient(180deg, #FFBE10 0%, #FB9510 100%)'
                  : item.times === item.upLimit
                  ? item.taskType === '2'
                    ? '#FFB365'
                    : '#FFD76B'
                  : 'linear-gradient(180deg, #FE9429 0%, #FC7011 100%)'
              "
              @click="complete(item)"
              >{{
                item.getIntegralTimes
                  ? "立即领取"
                  : item.times === item.upLimit
                  ? item.taskType === "2"
                    ? "已完成"
                    : "已领取"
                  : "去完成"
              }}</van-button
            >
          </template>
        </van-cell>
      </template>
      <van-skeleton
        title
        :row="3"
        style="padding: 20px 0"
        v-if="!list.list.length"
      />
      <!-- <van-cell>
        <template #title>
          <div>上传头像（0/1）<span>+20积分</span></div>
          <p class="introduction">上传头像并保存，得积分</p>
        </template>
        <template #right-icon>
          <van-button round color="#FFB365">已完成</van-button>
        </template>
      </van-cell>
      <van-cell>
        <template #title>
          <div>完成首笔订单<span>+50积分</span></div>
          <p class="introduction">首笔订单付款后24小时内不生产退款，得积分</p>
        </template>
        <template #right-icon>
          <van-button
            round
            color="linear-gradient(180deg, #FE9429 0%, #FC7011 100%)"
            >去完成</van-button
          >
        </template>
      </van-cell> -->
    </div>
    <!-- <div class="newcomer_task box_content">
      <h4>新人任务</h4>
      <van-cell>
        <template #title>
          <div>设置用户名称（0/1）<span>+20积分</span></div>
          <p class="introduction">填写用户名称并保存，得积分</p>
          <p class="time">2023-06-30任务到期</p>
        </template>
        <template #right-icon>
          <van-button
            round
            color="linear-gradient(180deg, #FE9429 0%, #FC7011 100%)"
            >去完成</van-button
          >
        </template>
      </van-cell>
      <van-cell>
        <template #title>
          <div>上传头像（0/1）<span>+20积分</span></div>
          <p class="introduction">上传头像并保存，得积分</p>
        </template>
        <template #right-icon>
          <van-button round color="#FFB365">已完成</van-button>
        </template>
      </van-cell>
      <van-cell>
        <template #title>
          <div>完成首笔订单<span>+50积分</span></div>
          <p class="introduction">首笔订单付款后24小时内不生产退款，得积分</p>
        </template>
        <template #right-icon>
          <van-button
            round
            color="linear-gradient(180deg, #FE9429 0%, #FC7011 100%)"
            >去完成</van-button
          >
        </template>
      </van-cell>
    </div>
    <div class="daily_tasks box_content">
      <h4>日常任务</h4>
      <van-cell>
        <template #title>
          <div>邀请新用户（1/5）<span>+100积分</span></div>
          <p class="introduction">
            每邀请1位好友，获得100积分， 每天最多可获500积分
          </p>
        </template>
        <template #right-icon>
          <van-button
            round
            color="linear-gradient(180deg, #FFBE10 0%, #FB9510 100%)"
            >立即领取</van-button
          >
        </template>
      </van-cell>
    </div> -->

    <van-overlay
      :show="tipsShow"
      z-index="10000"
      class-name="sign_in_successfully_tips"
    >
      <div class="title">
        <img src="@/assets/images/popup_head.png" /><span
          class="close"
          @click="tipsShow = false"
        ></span>
      </div>
      <div class="content">
        <h3>签到成功，送您奖励</h3>
        <h2>+{{ today.integral }}积分</h2>
        <p>
          本周已累计签到<span>{{ signInDaysNum }}</span
          >天
        </p>
        <p>
          周累计签到<span v-for="(item, i) in bonusPoints" :key="i"
            >{{ item.days }}天{{
              bonusPoints.length === i + 1 ? "" : "/"
            }}</span
          >可获得额外奖励
        </p>
        <van-button round color="#FB6E10" @click="tipsShow = false"
          >确认</van-button
        >
      </div>
    </van-overlay>

    <van-overlay
      :show="integralBannerShow"
      z-index="10002"
      class-name="points_claim_pop_up_window"
    >
      <img src="@/assets/images/lqjfbg.png" alt="" />
      <div class="wrapper" @click.stop>
        <img src="@/assets/images/jb_icon.png" alt="" />
        <p>
          恭喜获得{{
            Math.round(
              integralBanner.integral * integralBanner.getIntegralTimes
            )
          }}积分
        </p>
        <p>通过 [{{ integralBanner.taskName }}] 获得</p>
        <div>
          <button @click="integralBannerShow = false">去看看其他任务</button>
        </div>
      </div>
      <svg-icon icon-class="close_icon" @click="integralBannerShow = false" />
    </van-overlay>

    <van-overlay
      @click="shareShow = false"
      :show="shareShow"
      z-index="10000"
      class-name="share_tips"
    >
      <img src="@/assets/images/share_tips.png" />
    </van-overlay>
  </div>
</template>

<script>
import { WechatSharing } from "@/utils/sdk";
import { mapState } from "vuex";
export default {
  name: "sign-in",
  computed: {
    ...mapState("user", ["zphIntegral", "userInfo", "bank", "taskList"]),
  },
  data() {
    return {
      integral: [],
      ruleShow: false,
      tipsShow: false,
      shareShow: false,
      integralBannerShow: false,
      integralBanner: {
        integral: 0,
        getIntegralTimes: 0,
        taskName: "",
      },
      signInDaysList: [],
      isSignIn: null,
      signInDaysNum: 0,
      bonusPoints: [],
      today: {},
    };
  },
  async created() {
    let shareInfo = {
      title: "正品汇商城",
      desc: "正品汇认证交易平台，打破进口商、批发商、零售商的垄断地位，突破传统贸易的发展模式，重塑贸易结构，使零售商、批发商与企业直接接触，有效地减少中间环节，节约流动资本，提高交易效率。有需要防伪认证的产品都可以在平台进行交易，符合标准的产品将导入系统，并享有保险、金融等服务，平台对商品拥有全程的防伪溯源功能，可以充分保障消费者的权益。",
      imgUrl: require("@/assets/images/logo.png"),
      link: `#/home/homePage?id=${
        sessionStorage.getItem("userUuid")
          ? sessionStorage.getItem("userUuid")
          : "AB6B24CFF1094B46B3EF3327974DCFF0"
      }`,
    };
    WechatSharing(shareInfo);
    if (this.$route.query.automaticCheckIn) {
      await this.signIn();
    } else {
      await this.getSignInDaysList();
      await this.getCheckInRecord();
    }
    await this.$store.dispatch("user/getTaskQueryList");
    let firstOrderTask = this.taskList[0].list[2];
    if (firstOrderTask.times < firstOrderTask.upLimit) {
      const data = await this.$API.homePage.authorityActivity({
        object: {
          userId: this.userInfo.id,
        },
      });
      //
      if (data.data.data) {
        await this.$store.dispatch("user/taskFinished", firstOrderTask.id);
        await this.$store.dispatch("user/getTaskQueryList");
      }
    }
  },

  methods: {
    async getSignInDaysList() {
      this.Toast.loading({
        duration: 0,
        message: "加载中...",
        forbidClick: true,
      });
      await this.$API.user
        .checkInRuleDetails({
          object: {},
        })
        .then(async (res) => {
          // throw Error(res.data.msg);
          if (res.data.code !== "SUCCESS") throw Error(res.data.msg);
          if (!res.data.data.length) throw Error("签到列表为空");

          res.data.data.forEach((e) => {
            if (e.doubling || e.extraIntegral) this.bonusPoints.push(e);
          });
          this.signInDaysList = res.data.data;
        })
        .catch((e) => {
          this.Toast.clear();
          this.Toast.fail("获取签到列表失败");
          console.error(e.message);
          throw Error(e.message);
        });
      // console.log(new Date().getDay());
    },
    async getCheckInRecord() {
      await this.$API.user
        .checkInRecord({
          object: {
            userId: this.userInfo.id,
          },
        })
        .then((res) => {
          // throw Error(res.data.msg)
          if (res.data.code !== "SUCCESS") throw Error(res.data.msg);

          this.signInDaysNum = 0;
          let signIn = false;
          let weekDay = new Date().getDay();
          res.data.data.forEach((e) => {
            if (weekDay === e.weekDay) signIn = true;
            this.signInDaysNum++;
          });
          this.today = JSON.parse(
            JSON.stringify(this.signInDaysList[this.signInDaysNum])
          );
          if (this.today.doubling) {
            this.today.integral = this.today.integral * 2;
          } else if (this.today.extraIntegral) {
            this.today.integral += this.today.extraIntegral;
          }

          this.isSignIn = signIn;
          this.Toast.clear();
        })
        .catch((e) => {
          this.Toast.clear();
          this.Toast.fail("获取签到记录失败");
          console.error(e.message);
          throw Error(e.message);
        });
    },
    async signIn() {
      if (this.isSignIn) return;

      if (!this.signInDaysList.length) {
        await this.getSignInDaysList()
          .then(async (res) => {
            await this.signIn();
          })
          .catch((e) => {});
        return;
      } else if (this.isSignIn === null) {
        await this.getCheckInRecord()
          .then(async (res) => {
            await this.signIn();
          })
          .catch((e) => {});
        return;
      }
      // this.Toast.fail("今日已签到");
      await this.$API.user
        .insertCheckInRecord({
          object: {
            integral: this.today.integral,
            userId: this.userInfo.id,
          },
        })
        .then(async (res) => {
          if (res.data.code !== "SUCCESS") throw Error(res.data.msg);
          await this.$store.dispatch("user/getCusBankFilePathList");
          this.signInDaysNum++;
          // await this.getSignInDaysList();

          this.isSignIn = true;
          this.tipsShow = true;
        })
        .catch((e) => {
          this.Toast.fail("签到失败");
          console.error(e.message);
        });
    },
    async complete(val) {
      // console.log(val);
      // this.$store.dispatch("user/taskFinished");
      // return;
      if (val.getIntegralTimes) {
        this.integralBanner = val;
        this.integralBannerShow = true;
        await this.$API.user.AdjustmentIntegral({
          object: {
            availablePoints: this.bank.find((item) => {
              return item.bankId === "0";
            }).integral,
            bankId: "0",
            integral: Math.round(val.integral * val.getIntegralTimes),
            integralStatusUpdateReq: {
              taskId: val.id, //任务id
              taskType: val.type === "1" ? 1 : 0, //任务类型|1：常规任务|非日常任务：0
              userId: this.userInfo.id, //用户id
            },
            operatorName: this.userInfo.userEntity.actualName
              ? this.userInfo.userEntity.actualName
              : "",
            operatorUserId: this.userInfo.id,
            remarks: "",
            type: 6,
            userId: this.userInfo.id,
          },
        });
        await this.$store.dispatch("user/getTaskQueryList");
        await this.$store.dispatch("user/getCusBankFilePathList");
      } else if (val.times < val.upLimit) {
        if (val.url === "share") {
          this.shareShow = true;
        } else if (val.taskType === "2") {
          this.$router.push({
            path: val.url,
            query: {
              taskId: val.id,
            },
          });
        } else {
          this.$router.push({
            path: val.url,
            query: {
              taskId: val.countdownTask ? val.id : undefined,
            },
          });
        }

        // Task id
        // this.$store.dispatch("user/taskFinished", val);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@mixin box {
  margin: 0 15px;
  background: #fff;
  border-radius: 10px;
}
#sign_in {
  height: 100%;
  // overflow-y: scroll;
  padding: 46px 0 20px;
  // display: flex;
  // flex-direction: column;
  background: #f6f7fb;
  overflow-x: hidden;
  ::v-deep .van-overlay {
    background: rgba($color: #333, $alpha: 0.48);
  }
  .rule {
    position: absolute;
    line-height: 46px;
    top: 0;
    right: 15px;
    z-index: 1000;
    font-size: 16px;
    color: #fff;
  }
  ::v-deep .rule_popover {
    padding: 19px 15px;
    border-radius: 20px;
    .van-dialog__header {
      margin-bottom: 18px;
      padding: 0;
      font-size: 18px;
      font-weight: bold;
      color: #333333;
    }
    .van-dialog__content {
      li {
        margin-bottom: 14px;
        color: #a8a8a8;
        font-size: 14px;
      }
    }
    .van-dialog__footer {
      padding: 0;
      padding-top: 10px;
      width: 150px;
      margin: 0 auto;
      .van-button {
        height: 44px;
      }
    }
  }
  .top {
    width: 150%;
    // max-width: 500px;
    position: relative;
    left: -25%;
    border-radius: 0 0 50% 50%;
    background: linear-gradient(180deg, #ff9b2d 0%, #fb7313 100%);
    overflow: hidden;
    padding-top: 20px;
    // flex-shrink: 0;
    .box {
      width: 61.336%;
      // max-width: 500px;
      margin: 0 auto;
      background: #fecc9f;
      border-radius: 10px;
      .my_integraly {
        position: relative;
        img {
          display: block;
        }
        .details {
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          width: 50%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          overflow: hidden;
          color: #fb4e10;
          padding-top: 14px;
          span {
            margin-left: 9px;
            border-radius: 11px;
            border: 1px solid #fb4e10;
            padding: 2px 8px;
            font-size: 12px;
            .svg-icon {
              font-size: 10px;
            }
          }
          h3 {
            font-size: 34px;
          }
        }
      }
      .text {
        background: linear-gradient(180deg, #fef2e8 0%, #fee5d3 100%);
        margin: 18px 15px 0;
        padding: 10px;
        border-radius: 10px 10px 0 0;
        text-align: center;
        color: #fb4e10;
        box-shadow: 0px 0px 10px rgba(255, 255, 255, 0.4);
        a {
          color: #fb4e10;
          text-decoration: underline;
        }
      }
    }
  }
  .sign_in_module {
    @include box;
    padding-bottom: 30px;
    margin-top: 25px;
    .title {
      display: flex;
      align-items: center;
      margin-bottom: 24px;
      div {
        background: url("../../assets/images/sign_in_sprites.png") no-repeat;
        flex-shrink: 0;
        text-align: center;
        color: #f6f7fb;
      }
      p {
        margin-left: 12px;
        span {
          color: #fb4e10;
        }
      }
    }
    .content {
      padding: 0 12px;
      h4 {
        font-size: 16px;
        color: #333333;
        span {
          color: #fb4e10;
        }
        margin-bottom: 44px;
      }
      .days {
        display: flex;
        justify-content: space-between;
        .day {
          max-width: 44px;
          flex: 1;
          position: relative;
          text-align: center;
          margin-right: 3px;
          .integral {
            padding: 5px 0;
            border-radius: 4px;
            background: #f6f7fb;
            .num {
              line-height: 22px;
              font-size: 16px;
              font-weight: bold;
              color: #ffc426;
            }
            .icon {
              background: url("../../assets/images/sign_in_sprites.png")
                no-repeat;
              display: block;
              margin: 0 auto;
              margin-top: 2px;
            }
          }
          .tips {
            position: absolute;
            top: -35px;
            left: 0;
            right: 0;
            margin: 0 auto;
            background: url("../../assets/images/sign_in_sprites.png") no-repeat;
            color: #ffffff;
          }
          .number_day {
            font-size: 12px;
            color: #666666;
            line-height: 17px;
            margin-top: 9px;
            display: inline-block;
          }
        }
        .day:last-child {
          margin: 0;
        }
      }
    }
    .van-button {
      display: inherit;
      margin: 24px auto 0;
      padding: 0 34px;
      font-size: 16px;
      box-shadow: 0px 3px 6px 1px rgba(251, 78, 16, 0.16);
    }
  }
  .title_text {
    margin: 20px 15px;
    font-size: 18px;
  }
  // .newcomer_task {
  // }
  // .daily_tasks {
  // }

  .box_content {
    @include box;
    padding: 0 12px;
    margin-bottom: 20px;
    > h4 {
      padding-top: 16px;
      font-size: 18px;
      color: #333333;
    }
    .van-cell {
      padding: 20px 0;
      .van-cell__title {
        padding-right: 8px;
        > div {
          font-size: 16px;
          font-weight: bold;
          color: #333333;
          span {
            font-weight: normal;
            color: #fb4e10;
          }
        }
        .introduction {
          color: #999999;
        }
        .time {
          font-size: 12px;
          color: #f91c12;
        }
      }
      .van-button {
        width: 74px;
        height: 34px;
        padding: 0;
      }
    }
    .van-cell::after {
      left: 0;
      right: 0;
      border-color: #d9d9d9;
    }
  }
  .box_content:last-child {
    margin-bottom: 0;
  }
  .sign_in_successfully_tips {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 43px;
    font-size: 14px;
    background-color: rgba($color: #000000, $alpha: 0.48);

    .title {
      position: relative;
      width: 100%;
      max-width: 414px;
      img {
        width: 100%;
        display: block;
      }
      .close {
        position: absolute;
        right: 0;
        top: 0;
        width: 11.5%;
        height: 16%;
      }
    }

    .content {
      position: relative;
      top: -10px;
      background: #fff;
      width: 100%;
      max-width: 414px;
      text-align: center;
      padding: 0 15px 15px;
      border-radius: 0 0 20px 20px;
      h3 {
        font-size: 16px;
        color: #333333;
        line-height: 22px;
      }
      h2 {
        font-size: 32px;
        color: #fb6e10;
        line-height: 45px;
        margin: 25px 0 21px 0;
      }
      p {
        line-height: 20px;
        span {
          color: #fb4e10;
        }
      }
      p:nth-child(3) {
        color: #333333;
        margin-bottom: 4px;
      }
      p:nth-child(4) {
        color: #a8a8a8;
        margin-bottom: 20px;
      }
      .van-button {
        padding: 0 59px;
        font-size: 16px;
      }
    }
  }
  .points_claim_pop_up_window {
    background: rgba(51, 51, 51, 0.39);
    > img {
      width: 284px;
      height: 454px;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -65%);
    }
    .wrapper {
      position: absolute;
      left: 50%;
      top: 50%;
      text-align: center;
      transform: translate(-50%, -65%);
      width: 100%;
      height: 435px;
      img {
        width: 120px;
        height: 120px;
        display: block;
        margin: 120px auto 0;
      }
      p:nth-child(2) {
        font-size: 20px;
        line-height: 20px;
        font-family: Adobe Heiti Std;
      }

      p:nth-child(3) {
        font-size: 14px;
        margin-top: 4px;
        line-height: 28px;
        font-size: 12px;
        color: #999999;
      }
      > div {
        margin-top: 70px;
        button {
          width: 188px;
          height: 42px;
          background: linear-gradient(0deg, #fdc606, #f9dd5c);
          border-radius: 20px;
          border: none;
          font-size: 18px;
          font-family: Adobe Heiti Std;
          color: #ffffff;
          margin: 0 5px;
        }
      }
    }
    .svg-icon {
      position: absolute;
      left: 50%;
      top: 76%;
      transform: translate(-50%, 0);
      font-size: 32px;
    }
  }
  .share_tips {
    img {
      width: 50%;
      float: right;
      margin: 10px 10px 0 0;
    }
  }
}
</style>
